import gsap from 'gsap'
const duration = () => (window.innerWidth < 1200 ? 0.5 : 0.75)
const ease = 'power3.inOut'

let overlay = null
let oldBackgroundColor = null

function onBeforeLeave(el, payload) {}

function onLeave(el, done, payload) {
  return new Promise((resolve, reject) => {})
}

function onBeforeEnter(el, payload) {}

function onEnter(el, done, payload) {
  return new Promise(async (resolve, reject) => {
    document.querySelector('.overlay-wipe')?.remove()

    oldBackgroundColor = 'rgb(255, 255, 255)'

    overlay = document.createElement('div')
    overlay.classList.add('overlay-wipe')
    document.body.append(overlay)

    gsap.set(overlay, {
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      top: 0,
      left: 0,
      zIndex: 19,
      y: '-100vh',
      backgroundColor: oldBackgroundColor,
    })

    gsap.to(overlay, {
      duration: duration(),
      ease,
      y: '0vh',

      onComplete() {
        window.scrollTo(0, 1)
      },
    })

    await waitFor(() => el.querySelector('div'))

    gsap.set(el, {
      position: 'absolute',
      width: '100vw',
      top: 0,
      opacity: 0,
      zIndex: 10,
    })

    const bg =
      document.body.style.getPropertyValue('--theme-background-color') ||
      '0 0% 100%'

    gsap.set(el, { opacity: 1 })

    gsap.to(overlay, {
      duration: duration() / 1.5,
      ease,
      backgroundColor: `hsl(${bg})`,
    })

    gsap.to(overlay, {
      delay: duration() / 2,
      duration: duration(),
      ease,
      y: '100vh',
      onComplete() {
        resolve(done)
        el.removeAttribute('style')
        document.querySelector('.overlay-wipe')?.remove()
      },
    })
  })
}

function onAfterEnter(el, payload) {}

function onAfterLeave(el, payload) {}

export default {
  onBeforeEnter,
  onEnter,
  onAfterEnter,
  onBeforeLeave,
  onLeave,
  onAfterLeave,
}
