// helpers/dependencies
const { onPointerDown, onPointerMove, onPointerUp } = usePointer()
const { currentScroll, height } = useScreen()

const allWidgets = ref([])

if (process.client) {
  window.widgets = () => allWidgets.value
}

const state = {
  running: false,
  needUpdate: false,

  lastScroll: currentScroll.value,
  deltaScroll: 0,
  scrolledY: 0,
  pulledY: 0,

  isOpen: ref(false),

  HEADER_HEIGHT: computed(() => 5.2 * em.value),
  WIDGET_GAP: em,
}

// settings
const maxScroll = computed(() => {
  const nonIdleWidgets = allWidgets.value.filter(({ isIdle }) => !isIdle)
  const last = nonIdleWidgets[nonIdleWidgets.length - 1]
  return Math.max(
    0,
    last.heightOfPreviousWidgets +
    last.height +
    nonIdleWidgets.length * state.WIDGET_GAP.value -
    height.value
  )
})

const getContainerTopPosition = () => {
  allWidgets.value.forEach(widget => {
    if (!widget.container) return

    const { top } = widget.container.getBoundingClientRect()

    widget.containerTopPosition = top
  })
}

const setIsIdle = ({ widget }) => {
  if (widget.isGlobal || widget.isTop) {
    widget.isIdle = false
    return
  }

  if (isMobile.value) {
    widget.isIdle = widget.containerTopPosition > height.value
  } else {
    widget.isIdle =
      widget.targetPosition &&
      widget.containerTopPosition > widget.targetPosition
  }
}

const setTargetPosition = ({ widget }) => {
  if (!isMobile.value) {
    widget.targetPosition =
      widget.heightOfPreviousWidgets + state.WIDGET_GAP.value * widget.idx
    return
  }

  // console.log(state.isOpen.value)

  if (!widget.isIdle && state.isOpen.value) {
    widget.targetPosition =
      height.value -
      (widget.heightOfPreviousWidgets +
        state.WIDGET_GAP.value * widget.idx +
        widget.height)
  } else if (widget.isIdle) {
    widget.targetPosition = height.value + 100
  } else {
    const stackOffset = (totalStack - widget.stackOrder - 1) * 5
    const peakUp = height.value - 50
    widget.targetPosition = round(peakUp - stackOffset)
  }
}

let totalStack = 0

const setReferences = () => {
  const { stackOrder } = allWidgets.value.reduce(
    (acc, widget, idx, arr) => {
      if (!widget.container || !widget.element) return acc

      widget.idx = idx
      widget.invertedIdx = arr.length - idx
      widget.heightOfPreviousWidgets = round(acc.height)
      widget.container.style.height = widget.height + 'px'

      setIsIdle({ widget })

      widget.stackOrder = acc.stackOrder

      isMobile.value ? (widget.element.style.zIndex = 100 - widget.idx) : null

      setTargetPosition({ widget })

      acc.height += widget.height
      acc.stackOrder += widget.isIdle ? 0 : 1

      return acc
    },
    { height: state.HEADER_HEIGHT.value, stackOrder: 0 }
  )

  totalStack = stackOrder
}

const update = () => {
  getContainerTopPosition()
  setReferences()
  if (!state.running) resume()
}

const updateLightVersion = () => {
  allWidgets.value.forEach(widget => setTargetPosition({ widget }))
  if (!state.running) resume()
}

const getInterpolatedPosition = widget => {
  const offset = isMobile.value
    ? Math.max(0, 0.2 + widget.stackOrder * 0.05)
    : widget.idx * 0.05

  const scrollBounce = widget.isIdle ? 0 : state.deltaScroll * offset
  const pullBounce = widget.isIdle
    ? 0
    : Math.min(5, -state.pulledY / 10) * widget.idx

  const startPosition = isMobile.value
    ? height.value + 50
    : widget.targetPosition

  widget.interpolatedPosition = lerp(
    widget.interpolatedPosition || startPosition,
    widget.targetPosition + scrollBounce + pullBounce
  )

  // Condition to check if the widget is on top of the stack and isOpen is true
  const shouldDisplayText = widget.stackOrder === totalStack - 1 && state.isOpen.value

  // Display or hide the .widgets-text element based on the condition
  const textElement = widget.element.querySelector('.widgets-text')
  if (textElement) {
    textElement.style.opacity = shouldDisplayText ? '1' : '0'
    textElement.style.height = shouldDisplayText ? '100%' : '0'
    textElement.style.visibility = shouldDisplayText ? 'visible' : 'hidden'
  }

  return (
    round(widget.interpolatedPosition) &&
    round(widget.interpolatedPosition) === round(widget.targetPosition)
  )
}


const getInterpolatedScale = widget => {
  if (!isMobile.value) return null

  const scale = 1 - 0.035 * (totalStack - widget.stackOrder - 1)
  widget.interpolatedScale = lerp(
    widget.interpolatedScale ?? 1,
    state.isOpen.value ? 1 : scale
  )

  return widget.interpolatedScale
}

// runtime functions
// returns Boolean, which is true if the widget need another animation frame
const onTick = widget => {
  const hasReachedTargetPosition = getInterpolatedPosition(widget)
  const { firstElementChild } = widget.element

  if (!isMobile.value && widget.isIdle) {
    firstElementChild.style.removeProperty('transform')
    widget.element.style.removeProperty('position')
    widget.element.style.removeProperty('transform')
  } else {
    widget.element.style.position = 'fixed'
    widget.element.style.transform = `translateY(${widget.interpolatedPosition}px)`
    firstElementChild.style.transform = `scale(${getInterpolatedScale(widget)})`
  }

  return !widget.isIdle && !hasReachedTargetPosition
}

const rafCB = () => {
  state.running = true
  let runAnother = false
  state.deltaScroll = state.lastScroll - Math.max(0, currentScroll.value)

  allWidgets.value.forEach(widget => {
    if (!widget.element) return
    const needUpdate = onTick(widget)
    runAnother = runAnother || needUpdate
  })

  state.lastScroll = Math.max(0, currentScroll.value)

  if (!runAnother) {
    pause()
  }
}

const resume = () => {
  state.running = true

  const tick = () => {
    if (process.client && state.running) {
      requestAnimationFrame(tick)
    }

    rafCB()
  }

  tick()
}

const pause = () => (state.running = false)

watch([currentScroll, height], update, {
  immediate: true,
})

/**
 * pointer events
 * drag on mobile to open/close/scroll the widgets
 */
const widgetClicked = ref(false)
const wasAlreadyOpen = ref(false)
const isScrollable = ref(false)
const makeScrollable = useDebounceFn(() => (isScrollable.value = true), 1000)

const handleOpening = () => {
  state.scrolledY = 0
  state.pulledY = 0
  state.isOpen.value = true
  scrollLock(true)
  makeScrollable()

  // allWidgets.value.forEach(widget => {
  //   widget.element.classList.add('is-open')
  // })
}

const handleClosing = () => {
  state.scrolledY = 0
  state.pulledY = 0
  state.isOpen.value = false
  isScrollable.value = false
  scrollLock(false)
  update()

  // console.log('closing')

  // allWidgets.value.forEach(widget => {
  //   widget.element.classList.remove('is-open')
  // })
}

const handlePointerDown = e => {
  if (!isMobile.value) return

  widgetClicked.value = false

  if (!e.target.closest('.widget-element')) return

  wasAlreadyOpen.value = state.isOpen.value
  widgetClicked.value = true
}

const handlePointerMove = (e, { deltaY, pullY }) => {
  if (!widgetClicked.value || !isMobile.value) return

  state.pulledY = pullY.value

  if (state.isOpen.value && isScrollable.value) {
    state.scrolledY = clamp(0, state.scrolledY + deltaY.value, maxScroll.value)
  }

  if (!state.isOpen.value && pullY.value > 100) {
    handleOpening()
  }

  if (state.scrolledY === 0 && state.isOpen.value && pullY.value < -100) {
    handleClosing()
  }

  updateLightVersion()
}

const handlePointerUp = e => (state.pulledY = 0)

onPointerDown.add(handlePointerDown)
onPointerMove.add(handlePointerMove)
onPointerUp.add(handlePointerUp)

watch(state.isOpen, () => {
  if (!state.isOpen.value) handleClosing()
})

/**
 * addWidget
 */
const addWidget = widget => {
  setTimeout(() => {
    const { stop } = useMutationObserver(widget.container, update, {
      childList: true,
      subtree: true,
    })

    const { height: elementHeight } = useElementSize(widget.element)

    watch(elementHeight, update)

    const model = {
      ...widget,
      isIdle: true,
      idx: -1,
      interpolatedPosition: null,
      targetPosition: null,
      height: elementHeight,
      destroy: stop,
    }

    // add if not added
    if (!allWidgets.value.find(w => w.id === widget.id)) {
      if (widget.isGlobal) {
        allWidgets.value.unshift(model)
      } else {
        allWidgets.value.push(model)
      }
    }

    if (isMobile.value) {
      widget.element.value.addEventListener('click', handleWidgetClick)
    }
    update()
  })
}

const handleWidgetClick = () => {
  if (!state.isOpen.value) {
    handleOpening()
    updateLightVersion()
  }
}

const removeWidget = id => {
  const widgetToRemove = allWidgets.value.findIndex(widget => widget.id === id)

  //widgetToRemove.destroy()

  if (widgetToRemove >= 0) {
    allWidgets.value.splice(widgetToRemove, 1)
  }

  update()
}

const clearWidgets = () => {
  pause()
  allWidgets.value.forEach(widget => widget.isGlobal && widget.destroy())
  allWidgets.value = allWidgets.value.filter(w => w?.isGlobal || w?.persist)
}

export default () => {
  return {
    addWidget,
    removeWidget,
    clearWidgets,
    isOpen: state.isOpen,
    close: handleClosing,
  }
}
