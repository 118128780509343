import { default as _91case_93u2V5Pz73P3Meta } from "/opt/build/repo/pages/[case].vue?macro=true";
import { default as _91_91category_93_930FEJF4EBpYMeta } from "/opt/build/repo/pages/category/[[category]].vue?macro=true";
import { default as categoryhuUPEyBjX7Meta } from "/opt/build/repo/pages/category.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91page_93Rwnwm5dZJrMeta } from "/opt/build/repo/pages/page/[page].vue?macro=true";
import { default as _91page_934Vxu7AxBKtMeta } from "/opt/build/repo/pages/sandbox/[page].vue?macro=true";
import { default as indexOzPETQeSfYMeta } from "/opt/build/repo/pages/sandbox/index.vue?macro=true";
import { default as test_45pageS9Si8QUryjMeta } from "/opt/build/repo/pages/test-page.vue?macro=true";
import { default as _91_91year_93_93tVnidzVi5CMeta } from "/opt/build/repo/pages/years/[[year]].vue?macro=true";
import { default as years6NtQmJGdTrMeta } from "/opt/build/repo/pages/years.vue?macro=true";
export default [
  {
    name: "case",
    path: "/:case()",
    component: () => import("/opt/build/repo/pages/[case].vue")
  },
  {
    name: "category",
    path: "/category",
    component: () => import("/opt/build/repo/pages/category.vue"),
    children: [
  {
    name: "category-category",
    path: ":category?",
    component: () => import("/opt/build/repo/pages/category/[[category]].vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "page-page",
    path: "/page/:page()",
    component: () => import("/opt/build/repo/pages/page/[page].vue")
  },
  {
    name: "sandbox-page",
    path: "/sandbox/:page()",
    component: () => import("/opt/build/repo/pages/sandbox/[page].vue")
  },
  {
    name: "sandbox",
    path: "/sandbox",
    component: () => import("/opt/build/repo/pages/sandbox/index.vue")
  },
  {
    name: "test-page",
    path: "/test-page",
    meta: test_45pageS9Si8QUryjMeta || {},
    component: () => import("/opt/build/repo/pages/test-page.vue")
  },
  {
    name: "years",
    path: "/years",
    component: () => import("/opt/build/repo/pages/years.vue"),
    children: [
  {
    name: "years-year",
    path: ":year?",
    component: () => import("/opt/build/repo/pages/years/[[year]].vue")
  }
]
  }
]