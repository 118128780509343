const { width, currentScroll } = useScreen()
const body = ref(process.client ? document.body : null)
const { height } = useElementSize(body)

const clamp = (val, min = -2, max = 2) => Math.min(Math.max(val, min), max)

/**
 * progress: goes from 1 to -1.
 * 1: element is below or has just entered viewport from below
 * 0: middle of element is in the middle of viewport
 * -1: element was just scrolled by, or is above viewport
 */

const running = ref(false)
const interpolatedScroll = ref(null)

const rafCB = () => {
  parallaxElements.value.forEach(handleParallax)

  if (round(interpolatedScroll.value) === currentScroll.value) {
    pause()
  }
}

const pause = () => {
  running.value = false
}

const resume = () => {
  running.value = true

  const tick = () => {
    if (process.client && running.value) {
      requestAnimationFrame(tick)
    }

    interpolatedScroll.value = lerp(
      interpolatedScroll.value || currentScroll.value,
      currentScroll.value,
      0.05
    )

    rafCB()
  }

  tick()
}

const parallaxElements = ref([])

const handleParallax = item => {
  if (!item.element) return

  const bounding = item.element.getBoundingClientRect()

  const progress =
    (bounding.top + bounding.height / 2 - window.innerHeight / 2) /
    (window.innerHeight / 2 + bounding.height / 2)

  const output = clamp(
    item.inTop
      ? -(interpolatedScroll.value || currentScroll.value) / window.innerHeight
      : progress
  )

  if (output > -2 && output < 2) {
    item.rotation ? item.element.style.transform = `translateY(${output * item.amount * 25
      }vmin) rotate(${item.rotation}deg)` : item.element.style.transform = `translateY(${output * item.amount * 25
      }vmin)`

  }
}

watch(
  currentScroll,
  () => {
    if (!parallaxElements.value) return

    resume()
  },
  { immediate: true }
)

watch(
  [width, height],
  () => {
    if (!parallaxElements.value) return

    parallaxElements.value.forEach(handleParallax)
  },
  { immediate: true }
)

export const useParallax = ({ element, amount, inTop, rotation } = {}) => {
  if (element) {
    watch(element, () => {
      if (!element.value || !amount) return
      parallaxElements.value.push({ element, amount, inTop, rotation })
      setTimeout(() => {
        handleParallax({ element: element.value, amount, inTop, rotation })
        if (element.value) element.value.classList.add('loaded')
      }, 200)
    })
  }

  return {
    clearParallax: () => {
      parallaxElements.value = []
    },
  }
}

if (process.client) {
  window.parallax = () => parallaxElements.value
}
