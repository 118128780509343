const CHARS = `ABCDEFGHIJKLMNOPQRSTUVWXYZ.
ÉÈ&
0123456789ÅØÆ-
 `.split('');


const rununnununununu = async () => {

  var canvas = document.createElement('canvas');
  canvas.style.position = "absolute";
  canvas.style.left = "0px";
  canvas.style.top = "0px";
  canvas.style.zIndex = "100";
  canvas.style.background = 'red';
  document.body.appendChild(canvas);

  const ctx = canvas.getContext('2d')

  const fontSize = 100
  const fontFamily = 'Grotesk'

  // change this to the path of the font
  const fontFace = new FontFace(
    fontFamily,
    'url(fonts/PPRightGrotesk-CompactBlack.ttf)'
  )
  const font = await fontFace.load()
  document.fonts.add(font)

  ctx.font = `${fontSize}px ${fontFamily}`

  const measure = string => ctx.measureText(string).width

  const output = {}

  CHARS.forEach(char1 => {
    CHARS.forEach(char2 => {
      const kerning =
        (measure(char1 + char2) - (measure(char1) + measure(char2))) / fontSize

      if (kerning === 0) return

      output[char1 + char2] = kerning
    })
  })

  console.log(output)

}
setTimeout(() => {
  //rununnununununu()
}, 2000)

// output for Grotesk:
export const kerning = {
  "21": 0.00390625,
  "23": -0.00146484375,
  "24": -0.00732421875,
  "34": 0.0048828125,
  "37": -0.0078125,
  "41": -0.0185546875,
  "42": 0.01123046875,
  "43": 0.00732421875,
  "44": 0.01220703125,
  "47": -0.009765625,
  "51": -0.013671875,
  "70": -0.00732421875,
  "71": 0.01123046875,
  "74": -0.02587890625,
  "76": -0.00732421875,
  "77": 0.01708984375,
  "78": -0.0078125,
  "79": -0.00732421875,
  "84": 0.0048828125,
  "87": -0.0078125,
  "97": -0.00390625,
  "AA": 0.00634765625,
  "AC": -0.01904296875,
  "AG": -0.01904296875,
  "AI": -1.9073486328125e-8,
  "AJ": -0.01123046875,
  "AO": -0.01904296875,
  "AQ": -0.01904296875,
  "AS": -0.0078125,
  "AT": -0.04638671875,
  "AU": -0.01904296875,
  "AV": -0.04052734375,
  "AW": -0.033203125,
  "AX": 0.0048828125,
  "AY": -0.0537109375,
  "AZ": 0.0087890625,
  "A.": 0.009765605926513671,
  "A\n": -0.017089853286743163,
  "A&": -0.00732421875,
  "A0": -0.01904296875,
  "A3": -0.00390625,
  "A5": -0.00634765625,
  "A6": -0.01904296875,
  "A7": -0.0478515625,
  "A9": -0.01904296875,
  "AÅ": 0.00634765625,
  "AØ": -0.01904296875,
  "AÆ": 0.0048828125,
  "A-": -0.0063476753234863285,
  "A ": -0.017089853286743163,
  "BA": -0.00439453125,
  "BC": -0.00390625,
  "BG": -0.00390625,
  "BI": -1.9073486328125e-8,
  "BJ": 0.00732421875,
  "BO": -0.00390625,
  "BQ": -0.00390625,
  "BT": -0.00439453125,
  "BV": -0.01904296875,
  "BW": -0.01171875,
  "BX": -0.01708984375,
  "BY": -0.02099609375,
  "BZ": -0.0048828125,
  "B.": -1.9073486328125e-8,
  "B\n": -9.5367431640625e-9,
  "B0": -0.00390625,
  "B3": 0.0146484375,
  "B5": 0.00439453125,
  "B6": -0.00390625,
  "B7": -0.00390625,
  "B8": 0.0078125,
  "B9": -0.00390625,
  "BÅ": -0.00439453125,
  "BØ": -0.00390625,
  "BÆ": -0.00732421875,
  "B-": -1.9073486328125e-8,
  "B ": -9.5367431640625e-9,
  "CA": -0.01904296875,
  "CI": -1.9073486328125e-8,
  "CT": -0.00439453125,
  "CV": -0.01513671875,
  "CW": -0.01171875,
  "CX": -0.0244140625,
  "CY": -0.0283203125,
  "CZ": -0.00634765625,
  "C.": -0.01855470657348633,
  "C\n": -9.5367431640625e-9,
  "C7": -0.00390625,
  "CÅ": -0.01904296875,
  "CÆ": -0.02587890625,
  "C-": -1.9073486328125e-8,
  "C ": -9.5367431640625e-9,
  "DA": -0.01904296875,
  "DI": -1.9073486328125e-8,
  "DT": -0.00439453125,
  "DV": -0.01513671875,
  "DW": -0.01171875,
  "DX": -0.0244140625,
  "DY": -0.0283203125,
  "DZ": -0.00634765625,
  "D.": -0.01855470657348633,
  "D\n": -9.5367431640625e-9,
  "D7": -0.00390625,
  "DÅ": -0.01904296875,
  "DÆ": -0.02587890625,
  "D-": -1.9073486328125e-8,
  "D ": -9.5367431640625e-9,
  "EC": -0.00634765625,
  "EG": -0.00634765625,
  "EI": -1.9073486328125e-8,
  "EJ": -0.0087890625,
  "EO": -0.00634765625,
  "EQ": -0.00634765625,
  "ES": -0.00146484375,
  "ET": 0.00146484375,
  "E.": 0.0048827934265136715,
  "E\n": 2.86102294921875e-8,
  "E0": -0.00634765625,
  "E6": -0.00634765625,
  "E9": -0.00634765625,
  "EØ": -0.00634765625,
  "E-": -1.9073486328125e-8,
  "E ": 2.86102294921875e-8,
  "FA": -0.0390625,
  "FC": -0.00537109375,
  "FG": -0.00537109375,
  "FI": -1.9073486328125e-8,
  "FJ": -0.029296875,
  "FO": -0.00537109375,
  "FQ": -0.00537109375,
  "FS": -0.0048828125,
  "FT": 0.0048828125,
  "FZ": -0.00146484375,
  "F.": -0.057617168426513675,
  "F\n": 2.86102294921875e-8,
  "F&": -0.02099609375,
  "F0": -0.00537109375,
  "F1": 0.0087890625,
  "F3": 0.00390625,
  "F4": -0.01123046875,
  "F6": -0.00537109375,
  "F7": 0.00634765625,
  "F9": -0.00537109375,
  "FÅ": -0.0390625,
  "FØ": -0.00537109375,
  "FÆ": -0.06103515625,
  "F-": -1.9073486328125e-8,
  "F ": 2.86102294921875e-8,
  "GI": -1.9073486328125e-8,
  "GT": -0.0048828125,
  "GV": -0.01611328125,
  "GW": -0.01220703125,
  "GY": -0.0283203125,
  "G.": -1.9073486328125e-8,
  "G\n": -9.5367431640625e-9,
  "G7": -0.00390625,
  "G-": -1.9073486328125e-8,
  "G ": -9.5367431640625e-9,
  "HI": -1.9073486328125e-8,
  "H.": -1.9073486328125e-8,
  "H\n": -9.5367431640625e-9,
  "H-": -1.9073486328125e-8,
  "H ": -9.5367431640625e-9,
  "IA": -1.9073486328125e-8,
  "IB": -1.9073486328125e-8,
  "IC": -1.9073486328125e-8,
  "ID": -1.9073486328125e-8,
  "IE": -1.9073486328125e-8,
  "IF": -1.9073486328125e-8,
  "IG": -1.9073486328125e-8,
  "IH": -1.9073486328125e-8,
  "IJ": -1.9073486328125e-8,
  "IK": -1.9073486328125e-8,
  "IL": -1.9073486328125e-8,
  "IM": -1.9073486328125e-8,
  "IN": -1.9073486328125e-8,
  "IO": -1.9073486328125e-8,
  "IP": -1.9073486328125e-8,
  "IQ": -1.9073486328125e-8,
  "IR": -1.9073486328125e-8,
  "IS": -1.9073486328125e-8,
  "IT": -1.9073486328125e-8,
  "IU": -1.9073486328125e-8,
  "IV": -1.9073486328125e-8,
  "IW": -1.9073486328125e-8,
  "IX": -1.9073486328125e-8,
  "IY": -1.9073486328125e-8,
  "IZ": -1.9073486328125e-8,
  "I\n": 9.5367431640625e-9,
  "IÉ": -1.9073486328125e-8,
  "IÈ": -1.9073486328125e-8,
  "I&": -1.9073486328125e-8,
  "I0": -1.9073486328125e-8,
  "I1": 1.9073486328125e-8,
  "I2": -1.9073486328125e-8,
  "I3": -1.9073486328125e-8,
  "I4": -1.9073486328125e-8,
  "I5": -1.9073486328125e-8,
  "I6": -1.9073486328125e-8,
  "I7": -1.9073486328125e-8,
  "I8": -1.9073486328125e-8,
  "I9": -1.9073486328125e-8,
  "IÅ": -1.9073486328125e-8,
  "IØ": -1.9073486328125e-8,
  "IÆ": -1.9073486328125e-8,
  "I ": 9.5367431640625e-9,
  "JA": -0.01904296875,
  "JI": -1.9073486328125e-8,
  "J.": -0.0073242378234863285,
  "J\n": 2.86102294921875e-8,
  "JÅ": -0.01904296875,
  "JÆ": -0.01904296875,
  "J-": -1.9073486328125e-8,
  "J ": 2.86102294921875e-8,
  "KA": 0.0087890625,
  "KC": -0.0341796875,
  "KG": -0.0341796875,
  "KI": -1.9073486328125e-8,
  "KJ": -0.0126953125,
  "KO": -0.0341796875,
  "KQ": -0.0341796875,
  "KS": -0.02099609375,
  "KT": 0.00146484375,
  "KU": -0.00390625,
  "KV": 0.00146484375,
  "KX": 0.0048828125,
  "KY": 0.00146484375,
  "KZ": 0.00146484375,
  "K.": 0.001464824676513672,
  "K\n": -0.017089853286743163,
  "K&": -0.0078125,
  "K0": -0.0341796875,
  "K1": -0.0283203125,
  "K3": -0.0087890625,
  "K4": -0.01611328125,
  "K5": -0.00732421875,
  "K6": -0.0341796875,
  "K8": -0.01513671875,
  "K9": -0.0341796875,
  "KÅ": 0.0087890625,
  "KØ": -0.0341796875,
  "KÆ": 0.0048828125,
  "K-": -0.03076173782348633,
  "K ": -0.017089853286743163,
  "LA": 0.00146484375,
  "LC": -0.0087890625,
  "LG": -0.0087890625,
  "LI": -1.9073486328125e-8,
  "LJ": -0.00244140625,
  "LO": -0.0087890625,
  "LQ": -0.0087890625,
  "LS": -0.00634765625,
  "LT": -0.0732421875,
  "LU": -0.0048828125,
  "LV": -0.06494140625,
  "LW": -0.03857421875,
  "LY": -0.087890625,
  "LZ": 0.00146484375,
  "L.": 0.0063476371765136715,
  "L\n": -0.009765596389770507,
  "L&": -0.00146484375,
  "L0": -0.0087890625,
  "L1": -0.06494140625,
  "L3": 0.0029296875,
  "L4": 0.00146484375,
  "L6": -0.0087890625,
  "L7": -0.0576171875,
  "L9": -0.0087890625,
  "LÅ": 0.00146484375,
  "LØ": -0.0087890625,
  "L-": -0.014648456573486329,
  "L ": -0.009765596389770507,
  "MI": -1.9073486328125e-8,
  "M.": -1.9073486328125e-8,
  "M\n": -9.5367431640625e-9,
  "M-": -1.9073486328125e-8,
  "M ": -9.5367431640625e-9,
  "NI": -1.9073486328125e-8,
  "N.": -1.9073486328125e-8,
  "N\n": -9.5367431640625e-9,
  "N-": -1.9073486328125e-8,
  "N ": -9.5367431640625e-9,
  "OA": -0.01904296875,
  "OI": -1.9073486328125e-8,
  "OT": -0.00439453125,
  "OV": -0.01513671875,
  "OW": -0.01171875,
  "OX": -0.0244140625,
  "OY": -0.0283203125,
  "OZ": -0.00634765625,
  "O.": -0.01855470657348633,
  "O\n": -9.5367431640625e-9,
  "O7": -0.00390625,
  "OÅ": -0.01904296875,
  "OÆ": -0.02587890625,
  "O-": -1.9073486328125e-8,
  "O ": -9.5367431640625e-9,
  "PA": -0.04052734375,
  "PC": 0.00390625,
  "PG": 0.00390625,
  "PI": -1.9073486328125e-8,
  "PJ": -0.04443359375,
  "PO": 0.00390625,
  "PQ": 0.00390625,
  "PS": 0.00390625,
  "PT": 0.0029296875,
  "PV": -0.0146484375,
  "PW": -0.00732421875,
  "PX": -0.03173828125,
  "PY": -0.0234375,
  "PZ": -0.00439453125,
  "P.": -0.09667970657348633,
  "P\n": -0.013671846389770507,
  "P&": -0.03173828125,
  "P0": 0.00390625,
  "P2": 0.00390625,
  "P4": -0.029296875,
  "P6": 0.00390625,
  "P7": 0.00390625,
  "P9": 0.00390625,
  "PÅ": -0.04052734375,
  "PØ": 0.00390625,
  "PÆ": -0.0771484375,
  "P-": -0.0039062690734863285,
  "P ": -0.013671846389770507,
  "QA": -0.01904296875,
  "QI": -1.9073486328125e-8,
  "QT": -0.00439453125,
  "QV": -0.01513671875,
  "QW": -0.01171875,
  "QX": -0.0244140625,
  "QY": -0.0283203125,
  "QZ": -0.00634765625,
  "Q.": -0.01855470657348633,
  "Q\n": -9.5367431640625e-9,
  "Q7": -0.00390625,
  "QÅ": -0.01904296875,
  "QÆ": -0.02587890625,
  "Q-": -1.9073486328125e-8,
  "Q ": -9.5367431640625e-9,
  "RA": 0.0087890625,
  "RC": -0.0078125,
  "RG": -0.0078125,
  "RI": -1.9073486328125e-8,
  "RJ": -0.01123046875,
  "RO": -0.0078125,
  "RQ": -0.0078125,
  "RS": -0.01220703125,
  "RT": -0.009765625,
  "RU": -0.01171875,
  "RV": -0.0234375,
  "RW": -0.01611328125,
  "RX": 0.0087890625,
  "RY": -0.0283203125,
  "RZ": 0.0087890625,
  "R.": 0.0048827934265136715,
  "R\n": -0.004882822036743164,
  "R&": -0.0126953125,
  "R0": -0.0078125,
  "R2": 0.00146484375,
  "R4": -0.0078125,
  "R6": -0.0078125,
  "R7": -0.0087890625,
  "R9": -0.0078125,
  "RÅ": 0.0087890625,
  "RØ": -0.0078125,
  "RÆ": 0.00146484375,
  "R-": -0.01855470657348633,
  "R ": -0.004882822036743164,
  "SA": -0.01123046875,
  "SI": -1.9073486328125e-8,
  "ST": -0.01220703125,
  "SV": -0.02587890625,
  "SW": -0.00732421875,
  "SX": -0.0234375,
  "SY": -0.0341796875,
  "SZ": -0.0146484375,
  "S.": -1.9073486328125e-8,
  "S\n": -9.5367431640625e-9,
  "S1": -0.00732421875,
  "S7": -0.00390625,
  "SÅ": -0.01123046875,
  "SÆ": -0.02197265625,
  "S-": -0.002441425323486328,
  "S ": -9.5367431640625e-9,
  "TA": -0.04638671875,
  "TC": -0.00439453125,
  "TG": -0.00439453125,
  "TI": -1.9073486328125e-8,
  "TJ": -0.0966796875,
  "TO": -0.00439453125,
  "TQ": -0.00439453125,
  "TT": 0.0185546875,
  "TV": 0.009765625,
  "TW": 0.00244140625,
  "TX": 0.00244140625,
  "TY": 0.009765625,
  "TZ": 0.0087890625,
  "T.": -0.10400388717651367,
  "T\n": -0.01855465888977051,
  "T&": -0.029296875,
  "T0": -0.00439453125,
  "T1": 0.00244140625,
  "T3": 0.00390625,
  "T4": -0.04638671875,
  "T5": 0.00146484375,
  "T6": -0.00439453125,
  "T7": 0.00634765625,
  "T9": 0.00390625,
  "TÅ": -0.04638671875,
  "TØ": -0.00439453125,
  "TÆ": -0.06005859375,
  "T-": -0.052734394073486325,
  "T ": -0.01855465888977051,
  "UA": -0.01904296875,
  "UI": -1.9073486328125e-8,
  "U.": -0.0073242378234863285,
  "U\n": -9.5367431640625e-9,
  "UÅ": -0.01904296875,
  "UÆ": -0.01904296875,
  "U-": -1.9073486328125e-8,
  "U ": -9.5367431640625e-9,
  "VA": -0.04052734375,
  "VC": -0.01513671875,
  "VG": -0.01513671875,
  "VI": -1.9073486328125e-8,
  "VJ": -0.07080078125,
  "VO": -0.01513671875,
  "VQ": -0.01513671875,
  "VS": -0.01123046875,
  "VT": 0.009765625,
  "VV": 0.0087890625,
  "VW": 0.0087890625,
  "VX": 0.00927734375,
  "VY": 0.0087890625,
  "VZ": 0.00390625,
  "V.": -0.08300783157348633,
  "V\n": -0.017089853286743163,
  "V&": -0.0478515625,
  "V0": -0.01513671875,
  "V1": -0.01123046875,
  "V2": -0.01123046875,
  "V3": -0.00732421875,
  "V4": -0.0380859375,
  "V5": -0.00390625,
  "V6": -0.01513671875,
  "V7": 0.00634765625,
  "V8": -0.01123046875,
  "V9": -0.00390625,
  "VÅ": -0.04052734375,
  "VØ": -0.01513671875,
  "VÆ": -0.078125,
  "V-": -0.052734394073486325,
  "V ": -0.017089853286743163,
  "WA": -0.033203125,
  "WC": -0.01171875,
  "WG": -0.01171875,
  "WI": -1.9073486328125e-8,
  "WJ": -0.0439453125,
  "WO": -0.01171875,
  "WQ": -0.01171875,
  "WS": -0.00390625,
  "WT": 0.00244140625,
  "WV": 0.0087890625,
  "WW": 0.0087890625,
  "WX": 0.00537109375,
  "WY": 0.0087890625,
  "W.": -0.041503925323486325,
  "W\n": -0.012207040786743164,
  "W&": -0.04052734375,
  "W0": -0.01171875,
  "W1": -0.00390625,
  "W2": -0.00390625,
  "W4": -0.033203125,
  "W5": -0.00732421875,
  "W6": -0.01171875,
  "W7": 0.00634765625,
  "W8": -0.01123046875,
  "W9": -0.00390625,
  "WÅ": -0.033203125,
  "WØ": -0.01171875,
  "WÆ": -0.05126953125,
  "W-": -0.02587892532348633,
  "W ": -0.012207040786743164,
  "XA": 0.0048828125,
  "XC": -0.0244140625,
  "XG": -0.0244140625,
  "XI": -1.9073486328125e-8,
  "XJ": -0.0244140625,
  "XO": -0.0244140625,
  "XQ": -0.0244140625,
  "XS": -0.01953125,
  "XT": 0.00048828125,
  "XV": 0.0078125,
  "XW": 0.00439453125,
  "XX": 0.0087890625,
  "XY": 0.00439453125,
  "XZ": 0.0048828125,
  "X.": 0.008789043426513671,
  "X\n": -0.013671884536743164,
  "X&": -0.02392578125,
  "X0": -0.0244140625,
  "X1": -0.02587890625,
  "X3": -0.01123046875,
  "X4": -0.0087890625,
  "X5": -0.00732421875,
  "X6": -0.0244140625,
  "X8": -0.01123046875,
  "X9": -0.0244140625,
  "XÅ": 0.0048828125,
  "XØ": -0.0244140625,
  "XÆ": 0.0048828125,
  "X-": -0.040527362823486325,
  "X ": -0.013671884536743164,
  "YA": -0.0537109375,
  "YC": -0.02685546875,
  "YG": -0.02685546875,
  "YI": -1.9073486328125e-8,
  "YJ": -0.11376953125,
  "YO": -0.02685546875,
  "YQ": -0.02685546875,
  "YS": -0.01611328125,
  "YT": 0.009765625,
  "YV": 0.0087890625,
  "YW": 0.0087890625,
  "YX": 0.00537109375,
  "YY": 0.0087890625,
  "Y.": -0.10986330032348633,
  "Y\n": -0.02197262763977051,
  "Y&": -0.0576171875,
  "Y0": -0.02685546875,
  "Y1": -0.01220703125,
  "Y2": -0.0185546875,
  "Y3": -0.01611328125,
  "Y4": -0.0771484375,
  "Y5": -0.00390625,
  "Y6": -0.02685546875,
  "Y7": 0.00634765625,
  "Y8": -0.0234375,
  "Y9": -0.02685546875,
  "YÅ": -0.0537109375,
  "YØ": -0.02685546875,
  "YÆ": -0.0625,
  "Y-": -0.056152362823486325,
  "Y ": -0.02197262763977051,
  "ZA": 0.0087890625,
  "ZC": -0.0048828125,
  "ZG": -0.0048828125,
  "ZI": -1.9073486328125e-8,
  "ZJ": -0.00146484375,
  "ZO": -0.0048828125,
  "ZQ": -0.0048828125,
  "ZT": 0.00244140625,
  "ZV": 0.0048828125,
  "ZW": 0.00146484375,
  "ZX": 0.0048828125,
  "ZY": 0.00146484375,
  "ZZ": 0.0087890625,
  "Z.": 0.009765605926513671,
  "Z\n": -0.009765596389770507,
  "Z&": 0.00244140625,
  "Z0": -0.0048828125,
  "Z1": -0.013671875,
  "Z2": 0.0048828125,
  "Z3": 0.00732421875,
  "Z4": 0.013671875,
  "Z6": -0.0048828125,
  "Z8": 0.00732421875,
  "Z9": -0.0048828125,
  "ZÅ": 0.0087890625,
  "ZØ": -0.0048828125,
  "ZÆ": 0.0048828125,
  "Z-": -0.013671894073486329,
  "Z ": -0.009765596389770507,
  ".A": 0.009765605926513671,
  ".B": -1.9073486328125e-8,
  ".C": -0.01855470657348633,
  ".D": -1.9073486328125e-8,
  ".E": -1.9073486328125e-8,
  ".F": -1.9073486328125e-8,
  ".G": -0.01855470657348633,
  ".H": -1.9073486328125e-8,
  ".J": 0.008789043426513671,
  ".K": -1.9073486328125e-8,
  ".L": -1.9073486328125e-8,
  ".M": -1.9073486328125e-8,
  ".N": -1.9073486328125e-8,
  ".O": -0.01855470657348633,
  ".P": -1.9073486328125e-8,
  ".Q": -0.01855470657348633,
  ".R": -1.9073486328125e-8,
  ".S": -1.9073486328125e-8,
  ".T": -0.10400388717651367,
  ".U": -0.0073242378234863285,
  ".V": -0.08300783157348633,
  ".W": -0.041503925323486325,
  ".X": 0.008789043426513671,
  ".Y": -0.10986330032348633,
  ".Z": 0.001464824676513672,
  ".\n": 9.5367431640625e-9,
  ".É": -1.9073486328125e-8,
  ".È": -1.9073486328125e-8,
  ".&": -1.9073486328125e-8,
  ".0": -0.01855470657348633,
  ".1": -0.08203123092651367,
  ".2": -1.9073486328125e-8,
  ".3": -1.9073486328125e-8,
  ".4": 0.001464824676513672,
  ".5": -1.9073486328125e-8,
  ".6": -0.01855470657348633,
  ".7": -0.02685548782348633,
  ".8": -1.9073486328125e-8,
  ".9": -0.01855470657348633,
  ".Å": 0.009765605926513671,
  ".Ø": -0.01855470657348633,
  ".Æ": -1.9073486328125e-8,
  ". ": 9.5367431640625e-9,
  "\nA": -0.017089853286743163,
  "\nB": -9.5367431640625e-9,
  "\nC": -9.5367431640625e-9,
  "\nD": -9.5367431640625e-9,
  "\nE": 2.86102294921875e-8,
  "\nF": 2.86102294921875e-8,
  "\nG": -9.5367431640625e-9,
  "\nH": -9.5367431640625e-9,
  "\nI": 9.5367431640625e-9,
  "\nJ": -0.009765596389770507,
  "\nK": -9.5367431640625e-9,
  "\nL": 2.86102294921875e-8,
  "\nM": -9.5367431640625e-9,
  "\nN": -9.5367431640625e-9,
  "\nO": -9.5367431640625e-9,
  "\nP": -9.5367431640625e-9,
  "\nQ": -9.5367431640625e-9,
  "\nR": -9.5367431640625e-9,
  "\nS": -9.5367431640625e-9,
  "\nT": -0.01855465888977051,
  "\nU": -9.5367431640625e-9,
  "\nV": -0.017089853286743163,
  "\nW": -0.012207040786743164,
  "\nX": -0.013671884536743164,
  "\nY": -0.02197262763977051,
  "\nZ": -0.009765596389770507,
  "\n.": 9.5367431640625e-9,
  "\nÉ": 2.86102294921875e-8,
  "\nÈ": 2.86102294921875e-8,
  "\n&": -9.5367431640625e-9,
  "\n0": -9.5367431640625e-9,
  "\n1": -0.012207002639770507,
  "\n2": 2.86102294921875e-8,
  "\n3": 2.86102294921875e-8,
  "\n4": 2.86102294921875e-8,
  "\n5": 2.86102294921875e-8,
  "\n6": -9.5367431640625e-9,
  "\n7": -0.012207002639770507,
  "\n8": -9.5367431640625e-9,
  "\n9": -9.5367431640625e-9,
  "\nÅ": -0.017089853286743163,
  "\nØ": -9.5367431640625e-9,
  "\nÆ": -9.5367431640625e-9,
  "\n-": 9.5367431640625e-9,
  "ÉC": -0.00634765625,
  "ÉG": -0.00634765625,
  "ÉI": -1.9073486328125e-8,
  "ÉJ": -0.0087890625,
  "ÉO": -0.00634765625,
  "ÉQ": -0.00634765625,
  "ÉS": -0.00146484375,
  "ÉT": 0.00146484375,
  "É.": 0.0048827934265136715,
  "É\n": 2.86102294921875e-8,
  "É0": -0.00634765625,
  "É6": -0.00634765625,
  "É9": -0.00634765625,
  "ÉØ": -0.00634765625,
  "É-": -1.9073486328125e-8,
  "É ": 2.86102294921875e-8,
  "ÈC": -0.00634765625,
  "ÈG": -0.00634765625,
  "ÈI": -1.9073486328125e-8,
  "ÈJ": -0.0087890625,
  "ÈO": -0.00634765625,
  "ÈQ": -0.00634765625,
  "ÈS": -0.00146484375,
  "ÈT": 0.00146484375,
  "È.": 0.0048827934265136715,
  "È\n": 2.86102294921875e-8,
  "È0": -0.00634765625,
  "È6": -0.00634765625,
  "È9": -0.00634765625,
  "ÈØ": -0.00634765625,
  "È-": -1.9073486328125e-8,
  "È ": 2.86102294921875e-8,
  "&C": -0.00390625,
  "&G": -0.00390625,
  "&I": -1.9073486328125e-8,
  "&O": -0.00390625,
  "&Q": -0.00390625,
  "&T": -0.04296875,
  "&V": -0.048828125,
  "&W": -0.03076171875,
  "&Y": -0.06103515625,
  "&.": -1.9073486328125e-8,
  "&\n": -9.5367431640625e-9,
  "&0": -0.00390625,
  "&6": -0.00390625,
  "&9": -0.00390625,
  "&Ø": -0.00390625,
  "&-": -1.9073486328125e-8,
  "& ": -9.5367431640625e-9,
  "0A": -0.01904296875,
  "0I": -1.9073486328125e-8,
  "0T": -0.00439453125,
  "0V": -0.01513671875,
  "0W": -0.01171875,
  "0X": -0.0244140625,
  "0Y": -0.0283203125,
  "0Z": -0.00634765625,
  "0.": -0.01855470657348633,
  "0\n": -9.5367431640625e-9,
  "07": -0.00390625,
  "0Å": -0.01904296875,
  "0Æ": -0.02587890625,
  "0-": -1.9073486328125e-8,
  "0 ": -9.5367431640625e-9,
  "1I": 1.9073486328125e-8,
  "1.": 1.9073486328125e-8,
  "1\n": 2.86102294921875e-8,
  "1-": -1.9073486328125e-8,
  "1 ": 2.86102294921875e-8,
  "2I": -1.9073486328125e-8,
  "2V": -0.01123046875,
  "2W": -0.00390625,
  "2Y": -0.01611328125,
  "2Z": 0.00146484375,
  "2.": -1.9073486328125e-8,
  "2\n": 2.86102294921875e-8,
  "2-": -1.9073486328125e-8,
  "2 ": 2.86102294921875e-8,
  "3A": -0.00390625,
  "3I": -1.9073486328125e-8,
  "3V": -0.01123046875,
  "3W": -0.01123046875,
  "3X": -0.01123046875,
  "3Y": -0.0234375,
  "3.": -1.9073486328125e-8,
  "3\n": 2.86102294921875e-8,
  "3Å": -0.00390625,
  "3Æ": -0.01123046875,
  "3-": -1.9073486328125e-8,
  "3 ": 2.86102294921875e-8,
  "4I": -1.9073486328125e-8,
  "4J": 0.01123046875,
  "4T": -0.01708984375,
  "4V": -0.0087890625,
  "4W": -0.0048828125,
  "4X": -0.0048828125,
  "4Y": -0.0146484375,
  "4Z": -0.00244140625,
  "4.": -1.9073486328125e-8,
  "4\n": 2.86102294921875e-8,
  "4Æ": -0.00390625,
  "4-": -1.9073486328125e-8,
  "4 ": 2.86102294921875e-8,
  "5A": -0.00634765625,
  "5I": -1.9073486328125e-8,
  "5T": 0.00146484375,
  "5V": -0.00390625,
  "5W": -0.00732421875,
  "5X": -0.01123046875,
  "5Y": -0.00732421875,
  "5Z": -0.00390625,
  "5.": -1.9073486328125e-8,
  "5\n": 2.86102294921875e-8,
  "5Å": -0.00634765625,
  "5Æ": -0.01123046875,
  "5-": -1.9073486328125e-8,
  "5 ": 2.86102294921875e-8,
  "6I": -1.9073486328125e-8,
  "6J": 0.00732421875,
  "6T": -0.00439453125,
  "6V": -0.01513671875,
  "6W": -0.00732421875,
  "6X": -0.01611328125,
  "6Y": -0.01953125,
  "6Z": -0.00439453125,
  "6.": -1.9073486328125e-8,
  "6\n": -9.5367431640625e-9,
  "6Æ": -0.00390625,
  "6-": -1.9073486328125e-8,
  "6 ": -9.5367431640625e-9,
  "7A": -0.0478515625,
  "7C": -0.00732421875,
  "7G": -0.00732421875,
  "7I": -1.9073486328125e-8,
  "7J": -0.05517578125,
  "7O": -0.00732421875,
  "7Q": -0.00732421875,
  "7S": 0.00146484375,
  "7T": 0.00634765625,
  "7V": 0.00634765625,
  "7W": 0.00634765625,
  "7Y": 0.00634765625,
  "7Z": 0.00390625,
  "7.": -0.031738300323486325,
  "7\n": -0.004882783889770508,
  "7Å": -0.0478515625,
  "7Ø": -0.00732421875,
  "7Æ": -0.0380859375,
  "7-": -0.02197267532348633,
  "7 ": -0.004882783889770508,
  "8A": -0.00390625,
  "8I": -1.9073486328125e-8,
  "8V": -0.01123046875,
  "8W": -0.01123046875,
  "8X": -0.01123046875,
  "8Y": -0.0234375,
  "8.": -1.9073486328125e-8,
  "8\n": -9.5367431640625e-9,
  "8Å": -0.00390625,
  "8Æ": -0.01123046875,
  "8-": -1.9073486328125e-8,
  "8 ": -9.5367431640625e-9,
  "9A": -0.01904296875,
  "9I": -1.9073486328125e-8,
  "9T": -0.00439453125,
  "9V": -0.01513671875,
  "9W": -0.01171875,
  "9X": -0.0244140625,
  "9Y": -0.0283203125,
  "9Z": -0.00634765625,
  "9.": -0.01855470657348633,
  "9\n": -9.5367431640625e-9,
  "9Å": -0.01904296875,
  "9Æ": -0.02587890625,
  "9-": -1.9073486328125e-8,
  "9 ": -9.5367431640625e-9,
  "ÅA": 0.00634765625,
  "ÅC": -0.01904296875,
  "ÅG": -0.01904296875,
  "ÅI": -1.9073486328125e-8,
  "ÅJ": -0.01123046875,
  "ÅO": -0.01904296875,
  "ÅQ": -0.01904296875,
  "ÅS": -0.0078125,
  "ÅT": -0.04638671875,
  "ÅU": -0.01904296875,
  "ÅV": -0.04052734375,
  "ÅW": -0.033203125,
  "ÅX": 0.0048828125,
  "ÅY": -0.0537109375,
  "ÅZ": 0.0087890625,
  "Å.": 0.009765605926513671,
  "Å\n": -0.017089853286743163,
  "Å&": -0.00732421875,
  "Å0": -0.01904296875,
  "Å3": -0.00390625,
  "Å5": -0.00634765625,
  "Å6": -0.01904296875,
  "Å7": -0.0478515625,
  "Å9": -0.01904296875,
  "ÅÅ": 0.00634765625,
  "ÅØ": -0.01904296875,
  "ÅÆ": 0.0048828125,
  "Å-": -0.0063476753234863285,
  "Å ": -0.017089853286743163,
  "ØA": -0.01904296875,
  "ØI": -1.9073486328125e-8,
  "ØT": -0.00439453125,
  "ØV": -0.01513671875,
  "ØW": -0.01171875,
  "ØX": -0.0244140625,
  "ØY": -0.0283203125,
  "ØZ": -0.00634765625,
  "Ø.": -0.01855470657348633,
  "Ø\n": -9.5367431640625e-9,
  "Ø7": -0.00390625,
  "ØÅ": -0.01904296875,
  "ØÆ": -0.02587890625,
  "Ø-": -1.9073486328125e-8,
  "Ø ": -9.5367431640625e-9,
  "ÆC": -0.00634765625,
  "ÆG": -0.00634765625,
  "ÆI": -1.9073486328125e-8,
  "ÆJ": -0.0087890625,
  "ÆO": -0.00634765625,
  "ÆQ": -0.00634765625,
  "ÆS": -0.00146484375,
  "ÆT": 0.00146484375,
  "Æ.": 0.0048827934265136715,
  "Æ\n": -9.5367431640625e-9,
  "Æ0": -0.00634765625,
  "Æ6": -0.00634765625,
  "Æ9": -0.00634765625,
  "ÆØ": -0.00634765625,
  "Æ-": -1.9073486328125e-8,
  "Æ ": -9.5367431640625e-9,
  "-A": -0.0063476753234863285,
  "-B": -1.9073486328125e-8,
  "-C": -1.9073486328125e-8,
  "-D": -1.9073486328125e-8,
  "-E": -1.9073486328125e-8,
  "-F": -1.9073486328125e-8,
  "-G": -1.9073486328125e-8,
  "-H": -1.9073486328125e-8,
  "-J": -1.9073486328125e-8,
  "-K": -1.9073486328125e-8,
  "-L": -1.9073486328125e-8,
  "-M": -1.9073486328125e-8,
  "-N": -1.9073486328125e-8,
  "-O": -1.9073486328125e-8,
  "-P": -1.9073486328125e-8,
  "-Q": -1.9073486328125e-8,
  "-R": -1.9073486328125e-8,
  "-S": -0.0039062690734863285,
  "-T": -0.052734394073486325,
  "-U": -1.9073486328125e-8,
  "-V": -0.052734394073486325,
  "-W": -0.02587892532348633,
  "-X": -0.039062519073486325,
  "-Y": -0.056152362823486325,
  "-Z": -0.02587892532348633,
  "-\n": 9.5367431640625e-9,
  "-É": -1.9073486328125e-8,
  "-È": -1.9073486328125e-8,
  "-&": -1.9073486328125e-8,
  "-0": -1.9073486328125e-8,
  "-1": -0.02099611282348633,
  "-2": -0.011230487823486329,
  "-3": -1.9073486328125e-8,
  "-4": -1.9073486328125e-8,
  "-5": -1.9073486328125e-8,
  "-6": -1.9073486328125e-8,
  "-7": -0.02929689407348633,
  "-8": -1.9073486328125e-8,
  "-9": -1.9073486328125e-8,
  "-Å": -0.0063476753234863285,
  "-Ø": -1.9073486328125e-8,
  "-Æ": -0.02343751907348633,
  "- ": 9.5367431640625e-9,
  " A": -0.017089853286743163,
  " B": -9.5367431640625e-9,
  " C": -9.5367431640625e-9,
  " D": -9.5367431640625e-9,
  " E": 2.86102294921875e-8,
  " F": 2.86102294921875e-8,
  " G": -9.5367431640625e-9,
  " H": -9.5367431640625e-9,
  " I": 9.5367431640625e-9,
  " J": -0.009765596389770507,
  " K": -9.5367431640625e-9,
  " L": 2.86102294921875e-8,
  " M": -9.5367431640625e-9,
  " N": -9.5367431640625e-9,
  " O": -9.5367431640625e-9,
  " P": -9.5367431640625e-9,
  " Q": -9.5367431640625e-9,
  " R": -9.5367431640625e-9,
  " S": -9.5367431640625e-9,
  " T": -0.01855465888977051,
  " U": -9.5367431640625e-9,
  " V": -0.017089853286743163,
  " W": -0.012207040786743164,
  " X": -0.013671884536743164,
  " Y": -0.02197262763977051,
  " Z": -0.009765596389770507,
  " .": 9.5367431640625e-9,
  " É": 2.86102294921875e-8,
  " È": 2.86102294921875e-8,
  " &": -9.5367431640625e-9,
  " 0": -9.5367431640625e-9,
  " 1": -0.012207002639770507,
  " 2": 2.86102294921875e-8,
  " 3": 2.86102294921875e-8,
  " 4": 2.86102294921875e-8,
  " 5": 2.86102294921875e-8,
  " 6": -9.5367431640625e-9,
  " 7": -0.012207002639770507,
  " 8": -9.5367431640625e-9,
  " 9": -9.5367431640625e-9,
  " Å": -0.017089853286743163,
  " Ø": -9.5367431640625e-9,
  " Æ": -9.5367431640625e-9,
  " -": 9.5367431640625e-9
}