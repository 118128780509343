import Client from 'shopify-buy'
import { useStorage } from '@vueuse/core'

/**
 * helpers
 */
const formatPrice = price => parseInt(price) + ' kr'
const gidToId = (gid, type) => {
  let target

  switch (type) {
    case 'product':
      target = 'Product'
      break
    case 'variant':
      target = 'ProductVariant'
      break
  }

  return parseInt(gid.toString().replace(`gid://shopify/${target}/`, ''))
}
const idToGid = (id, type) => {
  if (type === 'product') {
    return 'gid://shopify/Product/' + id
  }
}

/**
 * state
 */
const checkout = process.client
  ? useStorage('cart', {}, sessionStorage)
  : ref({ id: 'server-side-dummy', lineItems: [] })
const _client = ref(null)

/**
 * internals
 */
const _setupShopifyClient = () => {
  const config = useRuntimeConfig().public

  _client.value = Client.buildClient({
    domain: config.SHOPIFY_HOSTNAME,
    storefrontAccessToken: config.SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  })
}

const _createCheckout = async () => {
  const newCheckout = await _client.value.checkout.create()
  _setCheckout(newCheckout)
  return newCheckout.id
}

const _setCheckout = newCheckout => {
  checkout.value = JSON.parse(JSON.stringify(newCheckout))
}

/**
 * public functions
 */
const initShopify = async () => {
  _setupShopifyClient()

  try {
    if (checkout.value.id) {
      // check the currently saved checkout against Shopify
      const existingCheckout = await _client.value.checkout.fetch(
        checkout.value.id
      )

      // if there's none, of if it's not alive
      if (!existingCheckout || existingCheckout.completedAt) {
        // clear out saved reference
        // a new one will potentially be created when something is added to cart
        checkout.value = []
      }
    }
  } catch (error) {
    // console.log('error fetching existing checkout', error)
  }
}

const addToCart = async variantId => {
  const checkoutId = checkout.value.id || (await _createCheckout())

  // console.log('adding:', variantId)

  const updatedCheckout = await _client.value.checkout.addLineItems(
    checkoutId,
    {
      variantId: `gid://shopify/ProductVariant/${gidToId(
        variantId,
        'variant'
      )}`,
      quantity: 1,
    }
  )

  _setCheckout(updatedCheckout)
}

const removeFromCart = async variantId => {
  const lineItem = checkout.value.lineItems.find(
    item =>
      item.variant.id ===
      `gid://shopify/ProductVariant/${gidToId(variantId, 'variant')}`
  )

  const updatedCheckout = await _client.value.checkout.removeLineItems(
    checkout.value.id,
    lineItem.id
  )

  _setCheckout(updatedCheckout)
}

/**
 * api
 */
export default () => {
  return {
    checkout,

    initShopify,
    addToCart,
    removeFromCart,

    formatPrice,
    gidToId,
    idToGid,
  }
}
