/**
 * calls the /server api with default values
 */

export default async (url, options = {}) => {
  const route = useRoute()

  // first '/api' is to hit /server, second is to hit element-api
  const { data, error, pending } = await useFetch(computed(() => '/api/api/' + unref(url) + '.json'), {
    key: JSON.stringify({ url: unref(url), query: unref(options.query) }),
    query: {
      token: route.query.token,
      ...(unref(options.query) || {}),
    },
  })

  if (error.value) {
    throw createError({ statusCode: 404, fatal: true })
  }

  // cached response has a different structure..
  return {
    data: data.value?.query ? ref(data.value.data) : data,
    error: ref(data.value?.error) || error,
    pending
  }
}
