const delay = (ms = 0) => new Promise(r => setTimeout(r, ms))

const { getCurrentTheme } = useTheme()

const durationLeave = () => (window.innerWidth < 1200 ? 0.5 : 0.8)
const durationEnter = () => (window.innerWidth < 1200 ? 0.5 : 1)

let overlay = null

let oldTheme = null
let oldPage = null

function onBeforeLeave(el, payload) {
  const currentTheme = getCurrentTheme()

  oldTheme = currentTheme
  oldPage = el

  // remove any old overlay
  document.querySelector('.overlay-wipe')?.remove()

  overlay = document.createElement('div')
  overlay.classList.add('overlay-wipe')

  Object.assign(overlay.style, {
    position: 'fixed',
    width: '100vw',
    height: '120vh',
    top: 0,
    left: 0,
    zIndex: 19,
    transform: 'translateY(calc(-120vh - 1px))',
    backgroundColor: currentTheme.background.rgbString,

    outline: '1px solid rgba(255, 255, 255, 0.3)',

    // opacity: 0
  })

  document.body.append(overlay)

  el.style.zIndex = 2
}

async function onLeave(el, done, payload) {
  const { currentScroll } = useScreen()
  const duration = durationLeave()
  const { background, text } = oldTheme

  el.style.backgroundColor = background.rgbString
  el.style.color = text.rgbString
  el.firstElementChild?.style.setProperty('--text-color', text.hslString)
  el.firstElementChild?.style.setProperty(
    '--background-color',
    background.hslString
  )

  const topOfScreen = currentScroll.value + 'px'
  const bottomOfScreen = currentScroll.value + window.innerHeight + 'px'

  const visible = `polygon(0vw ${topOfScreen}, 100vw ${topOfScreen}, 100vw ${bottomOfScreen}, 0vw ${bottomOfScreen})`
  el.style.clipPath = visible

  await delay(0)

  el.style.transformOrigin = 'top'
  el.style.transition = `all ${duration}s cubic-bezier(0.32, 0, 0.67, 0)`
  el.style.transform = 'translateY(10vh)'

  const hidden = `polygon(0vw ${bottomOfScreen}, 100vw ${bottomOfScreen}, 100vw ${bottomOfScreen}, 0vw ${bottomOfScreen})`
  el.style.clipPath = hidden

  overlay.style.transition = `transform ${duration}s cubic-bezier(0.32, 0, 0.67, 0)`
  overlay.style.transform = 'translateY(10vh)'

  await delay((duration + durationLeave()) * 1000)

  done()
}

async function onEnter(el, done, payload) {
  el.style.transform = 'translateY(-10vh)'

  await delay(durationLeave() * 1000)

  const duration = durationEnter()
  const { background, text } = getCurrentTheme()

  // oldPage.style.zIndex = -1

  el.style.backgroundColor = background.rgbString
  el.style.color = text.rgbString

  el.style.transition = `transform ${duration}s cubic-bezier(0.33, 1, 0.68, 1)`
  el.style.transform = 'translateY(0)'

  overlay.style.transition = `all ${duration}s cubic-bezier(0.33, 1, 0.68, 1)`
  overlay.style.transform = 'translateY(120vh)'

  await delay(duration * 1000)

  done()
}

function onAfterEnter(el, payload) {
  setTimeout(() => el?.removeAttribute('style'))
  overlay?.remove()
}

export default {
  onBeforeLeave,
  onLeave,

  onEnter,
  onAfterEnter,
}
