<script setup>
// import { Application } from '@splinetool/runtime'

// Props with validation and defaults
const props = defineProps({
  url: String,
  canvasId: {
    type: String,
    default: 'spline-canvas',
  },
})

const canvasRef = ref(null)
const splineInstance = ref(null)

const emit = defineEmits(['loaded', 'error'])

onMounted(async () => {
  if (canvasRef.value) {
    const spline = await import('@splinetool/runtime')
    const { Application } = spline
    splineInstance.value = new Application(canvasRef.value)

    splineInstance.value
      .load(props.url)
      .then(() => emit('loaded'))
      .catch(error => emit('error', error))
  }
})

// Methods to control the Spline application
const play = () => splineInstance.value?.play()
const pause = () => splineInstance.value?.stop()
const setVariables = variables => splineInstance.value?.setVariables(variables)
const setVariable = (name, value) =>
  splineInstance.value?.setVariable(name, value)
const getVariables = () => splineInstance.value?.getVariables()
const getVariable = name => splineInstance.value?.getVariable(name)
const stop = () => splineInstance.value?.stop()
const setBackgroundColor = color =>
  splineInstance.value?.setBackgroundColor(color)
const getAllObjects = () => splineInstance.value?.getAllObjects()
const getSplineEvents = () => splineInstance.value?.getSplineEvents()
const setZoom = zoom => splineInstance.value?.setZoom(zoom)
const setSize = (width, height) => splineInstance.value?.setSize(width, height)
const findObjectById = uuid => splineInstance.value?.findObjectById(uuid)
const findObjectByName = name => splineInstance.value?.findObjectByName(name)
const emitEvent = (eventName, nameOrUuid) =>
  splineInstance.value?.emitEvent(eventName, nameOrUuid)
const emitEventReverse = (eventName, nameOrUuid) =>
  splineInstance.value?.emitEventReverse(eventName, nameOrUuid)
const addEventListener = (eventName, cb) =>
  splineInstance.value?.addEventListener(eventName, cb)
const removeEventListener = (eventName, cb) =>
  splineInstance.value?.removeEventListener(eventName, cb)
const spline = splineInstance

defineExpose({
  spline,
  play,
  pause,
  stop,
  setVariables,
  setVariable,
  getVariables,
  getVariable,
  setBackgroundColor,
  getAllObjects,
  getSplineEvents,
  setZoom,
  setSize,
  findObjectById,
  findObjectByName,
  emitEvent,
  emitEventReverse,
  addEventListener,
  removeEventListener,
})
</script>

<template>
  <canvas ref="canvasRef" :id="canvasId"></canvas>
</template>

<style lang="postcss">
div {
}
</style>
