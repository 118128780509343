const onPointerDowns = ref([])
const onPointerMoves = ref([])
const onPointerUps = ref([])

const pressedAtLocation = ref(null)
const pullY = ref(0)
const lastPull = ref(0)
const deltaY = ref(0)

if (process.client) {
  window.addEventListener('pointerdown', e => {
    pressedAtLocation.value = e.clientY
    lastPull.value = e.clientY

    onPointerDowns.value.forEach(callback => {
      callback(e)
    })
  })

  window.addEventListener(
    'pointermove',
    e => {
      if (!pressedAtLocation.value) return

      // total amount of pull from the pointerdown
      pullY.value = pressedAtLocation.value - e.clientY

      // total amount of pull from the last pointermove
      deltaY.value = lastPull.value - e.clientY

      onPointerMoves.value.forEach(callback => {
        callback(e, { deltaY, pullY })
      })

      lastPull.value = e.clientY
    },
    { cancelable: true }
  )

  window.addEventListener('pointerup', e => {
    onPointerUps.value.forEach(callback => {
      callback(e)
    })

    pressedAtLocation.value = null
    pullY.value = 0
  })
}

export default () => {
  return {
    onPointerDown: {
      add: callback => onPointerDowns.value.push(callback),
      remove: callback => onPointerDowns.value.filter(cb => cb !== callback),
    },
    onPointerMove: {
      add: callback => onPointerMoves.value.push(callback),
      remove: callback => onPointerMoves.value.filter(cb => cb !== callback),
    },
    onPointerUp: {
      add: callback => onPointerUps.value.push(callback),
      remove: callback => onPointerUps.value.filter(cb => cb !== callback),
    },
  }
}
