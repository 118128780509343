// fetch seomatic data,
// keeps itself up-to-date on page shifts
export default async (overwritingPath) => {
  const route = useRoute()
  const url = '/actions/seomatic/meta-container/all-meta-containers/'

  const { data } = await useFetch('/api/' + url, {
    query: {
      uri: computed(() => overwritingPath || route.path),
      asArray: true,
    },
  })

  return {
    seomatic: computed(() => ({
      title: data.value?.MetaTitleContainer.title.title,
      meta: Object.values(data.value?.MetaTagContainer || {}).filter(
        tag => tag.content
      ),
      link: Object.values(data.value?.MetaLinkContainer || {}).filter(
        tag => tag.rel
      ),
      script: [
        ...Object.values(data.value?.MetaJsonLdContainer || {}).map(schema => ({
          type: 'application/ld+json',
          children: JSON.stringify(schema),
        })),
        ...Object.values(data.value?.MetaScriptContainer || {}).map(tag => ({
          children: tag.script,
        })),
      ],
    })),
    bodyScripts: computed(() =>
      Object.values(data.value?.MetaScriptContainer || {}).map(
        tag => tag.bodyScript
      )
    ),
  }
}
