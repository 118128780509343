function onBeforeEnter(el, payload) {}

function onEnter(el, done, payload) {
  // call this when you're done with your enter transition
  done()
}

function onAfterEnter(el, payload) {}

function onBeforeLeave(el, payload) {}

function onLeave(el, done, payload) {
  // call this when you're done with your enter transition
  done()
}

function onAfterLeave(el, payload) {}

function onEnterCancelled() {}

function onLeaveCancelled() {}

export default {
  onBeforeEnter,
  onEnter,
  onAfterEnter,
  onBeforeLeave,
  onLeave,
  onAfterLeave,

  onEnterCancelled,
  onLeaveCancelled,
}
