// dependencies
const { currentScroll, width, height } = useScreen()

const iosThemeIsUpdating = ref(false)
const lastHex = ref('#ffffff')
const progress = ref(0)

/**
 * fallback for when a page has no theme defined in the CMS
 */
const BLACK_WHITE_THEME = {
  text: {
    hsl: ['0', '0%', '0%'],
    opacity: 100,
  },
  background: {
    hsl: ['0', '0%', '100%'],
    opacity: 100,
  },
}

/**
 * updated continuously,
 * based on scroll position
 */
const currentTheme = ref(null)

const theme = {
  get() {
    return { ...currentTheme.value }
  },

  set(theme) {
    if (theme) {
      currentTheme.value = theme
    } else {
      currentTheme.value = defaultTheme.value
    }

    if (currentTheme.value) {
      setGlobalTheme(currentTheme.value)
    }
  },


  isSame(theme1, theme2) {
    const hash = theme =>
      theme?.background.hsl?.toString() + theme?.text.hsl?.toString()
    return hash(theme1) === hash(theme2)
  },
}

/**
 * cue used to prepare themes to be used on a page,
 * moved from cue to themesArray in initThemes()
 */
const rowThemes = ref([])
const defaultTheme = ref(BLACK_WHITE_THEME)

const cuedRowThemes = ref([])
const cuedDefaultTheme = ref(null)

const cuer = {
  cue({ rowTheme, defaultTheme }) {
    if (rowTheme) {
      cuedRowThemes.value.push(rowTheme)
    }

    if (defaultTheme) {
      cuedDefaultTheme.value = defaultTheme
    }
  },

  promote() {
    if (cuedRowThemes.value.length) {
      rowThemes.value = [...cuedRowThemes.value]
      cuedRowThemes.value = []
    }

    if (cuedDefaultTheme.value) {
      defaultTheme.value = unref(cuedDefaultTheme.value)
      cuedDefaultTheme.value = null
    }
  },

  clear() {
    defaultTheme.value = BLACK_WHITE_THEME
    rowThemes.value = rowThemes.value.filter(item => !item.element)
  },
}

/**
 * helpers to extract/convert theme data to usable values
 */
const getValues = colors => {
  const hsl = colors?.hsl || ['0', '0', '100']
  const [h, s, l] = hsl.map(v => parseInt(v.replace('%', '')))
  const [r, g, b] = HSLToRGB(h, s, l)

  return {
    rgb: [r, g, b], // [10, 10, 10]
    rgbString: `rgb(${r}, ${g}, ${b})`,
    hsl: [h, s, l], // [0, 0, 30]
    hslString: `${h} ${s}% ${l}%`,
  }
}

const getTheme = theme => {
  return {
    background: getValues(theme?.background),
    text: getValues(theme?.text),
  }
}

/**
 * updates the actual css variables used in the frontend
 */
const setGlobalTheme = theme => {
  if (!process.client) return

  const { background, text } = getTheme(
    unref(theme) || unref(defaultTheme.value) || BLACK_WHITE_THEME
  )

  if (!iosThemeIsUpdating.value && width.value < 1200) {
    changeIOSTheme({ background })
  }

  document.body.style.setProperty('--theme-text-color', text.hslString)
  document.body.style.setProperty(
    '--theme-background-color',
    background.hslString
  )
}

/**
 * loop though the current themes and find the one in view,
 * defaults to defaultTheme
 */
const TRIGGER_POINT = computed(() => {
  return height.value / 2
})

const updateCurrentTheme = () => {
  const rowInView = rowThemes.value.findLast(row => {
    if (!row.element) return

    const { top, bottom } = useElementBounding(row.element)
    if (currentScroll.value === 0 && row.isTopElement && top.value <= 0) {
      return true
    }
    const topValue = top.value <= 0 ? 0 : top.value
    return (
      topValue <= TRIGGER_POINT.value && bottom.value >= TRIGGER_POINT.value
    )
  })
  theme.set(rowInView?.theme)
}

/**
 * scroll handler/updater
 */
watch([currentScroll, width], updateCurrentTheme, {
  immediate: true,
})

// watch(currentScroll, () => {
//   console.log('scroll changed', currentScroll.value)
// })

/**
 * api
 */
export default function ({ element, theme, isTopElement, defaultTheme } = {}) {
  if (defaultTheme) {
    cuer.cue({ defaultTheme })
  }

  if (element && theme) {
    cuer.cue({ rowTheme: { element, theme, isTopElement } })
  }

  return {
    getCSSVariables: theme => {
      if (!theme) return {}
      const { background, text } = getTheme(theme)
      return {
        [`--text-color`]: text.hslString,
        [`--background-color`]: background.hslString,
      }
    },

    clearThemes() {
      cuer.clear()
    },

    initThemes() {
      cuer.promote()
      updateCurrentTheme()
    },

    refreshTheme: () => {
      cuer.clear()
      cuer.promote()
      updateCurrentTheme()
    },

    getCurrentTheme() {
      return getTheme({ ...currentTheme.value })
    },

  }
}

if (process.client) {
  window.themes = () => {
    return {
      themes: rowThemes.value,
      defaultTheme: defaultTheme.value,
    }
  }
}

/**
 * update mobile theme color
 */
function updateIOSTheme(newHex) {
  progress.value = Math.max(1, progress.value + 0.06)

  useHead({
    meta: [
      {
        name: 'theme-color',
        content: lerpColor(lastHex.value, newHex, progress.value),
      },
    ],
  })

  if (progress.value >= 1) {
    pause()
    progress.value = 0
    lastHex.value = newHex
  }
}

/**
 * animation loop,
 * used to change the ios browser theme
 */

function changeIOSTheme({ background }) {
  iosThemeIsUpdating.value = true

  const tick = () => {
    if (iosThemeIsUpdating.value) requestAnimationFrame(tick)
    updateIOSTheme(HSLToHEX(...background.hsl))
  }

  tick()
}

function pause() {
  iosThemeIsUpdating.value = false
}
