<script setup>
const props = defineProps({
  data: [Object, null],
  loadingThumbnail: Boolean,
})

const objectPosition = computed(
  () => `${props.data.focalPoint.x * 100}% ${props.data.focalPoint.y * 100}%`
)

const objectFit = computed(() => {
  return (
    props.data.objectFit ||
    (['png', 'svg', 'gif'].includes(props.data.extension) ? 'contain' : 'cover')
  )
})
const isEager = inject('eager-image', false)
</script>

<template>
  <div
    v-if="data"
    class="image-container"
    :style="{
      '--object-fit': objectFit,
      '--object-position': objectPosition,
    }"
  >
    <!-- 'background-image': `url(${data.placeholder})`, -->
    <img
      class="image"
      v-bind="data.attributes"
      :loading="isEager ? 'eager' : 'lazy'"
    />
  </div>
</template>

<style lang="postcss" scoped>
.image-container {
  will-change: transform;
  background-size: var(--object-fit);
  background-position: var(--object-position);
  background-repeat: no-repeat;

  width: 100%;
  height: 100%;

  &.loaded {
    background-image: none !important;

    img {
      opacity: 1;
    }
  }
}

img {
  /* transition: opacity 100ms ease; */
  /* opacity: 0; */

  width: 100%;
  height: 100%;

  object-fit: var(--object-fit);
  object-position: var(--object-position);

  display: flex;
}
</style>
