function preventDefault(e) {
  e.preventDefault()
}



function disableScroll(target) {
  if (!target) {
    document.body.addEventListener('touchmove', preventDefault, {
      passive: false,
    })
    document.body.addEventListener('DOMMouseScroll', preventDefault, {
      passive: false,
    });
    document.body.addEventListener('wheel', preventDefault, {
      passive: false,
    });
  }
  else {
    target.addEventListener('touchmove', preventDefault, {
      passive: false,
    })
    target.addEventListener('DOMMouseScroll', preventDefault, {
      passive: false,
    });
    target.addEventListener('wheel', preventDefault, {
      passive: false,
    });
  }
}

function enableScroll(target) {
  if (!target) {
    document.body.removeEventListener('touchmove', preventDefault)
    document.body.removeEventListener('DOMMouseScroll', preventDefault);
    document.body.removeEventListener('wheel', preventDefault);
  }
  else {
    target.removeEventListener('touchmove', preventDefault)
    target.removeEventListener('DOMMouseScroll', preventDefault);
    target.removeEventListener('wheel', preventDefault);
  }
}

export default (lock, target) => {
  if (process.client) {
    if (lock) {
      document.body.style.overflow = 'hidden'
      disableScroll(target)
    } else {
      document.body.style.removeProperty('overflow')
      document.body.style.removeProperty('height')
      enableScroll(target)
    }
  }
}