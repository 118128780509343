const labels = ref(null)

export const fetchLabels = async () => {
  const { data } = await useCmsFetch('global/language')

  labels.value = data.value
}

/**
 * labels comes from cms
 * Globals -> Translations
 *
 * useLabel('global.readMore') -> Læs mere
 * useLabel('entry.writtenBy', { name: 'Foo' }) -> Skriven af Foo
 */
export const useLabel = (handle, variables) => {
  return computed(() => {
    if (!labels.value) return ''

    if (!labels.value[handle]) {
      console.warn('No translations found for: ' + handle)
      return ''
    }

    if (!variables) {
      return labels.value[handle]
    }

    const string = Object.entries(variables).reduce((acc, [key, val]) => {
      return acc.replace(`{${key}}`, val)
    }, labels.value[handle])

    return string
  }).value
}
