<script setup>
// onMounted(async () => {
import Matter from 'matter-js'

// console.log(Matter)

// import Matter from
const container = ref(null)
const matterCanvas = ref(null)

let engine = null
let world = null
let render = null
let runner = null
let mouse = null
let mouseConstraint = null
let canvas = null

// const Matter = (await import('matter-js')).default

let Engine = Matter.Engine,
  Render = Matter.Render,
  Runner = Matter.Runner,
  MouseConstraint = Matter.MouseConstraint,
  Mouse = Matter.Mouse,
  Body = Matter.Body,
  Bodies = Matter.Bodies,
  Common = Matter.Common,
  Composite = Matter.Composite,
  Events = Matter.Events

let title = null,
  link = null,
  ceiling = null,
  ground = null,
  wallLeft = null,
  wallRight = null

let resizeTimer = null,
  prevWidth = null,
  prevHeight = null

let errorTitle = null,
  errorTitleInner = null,
  errorTitleRect = null,
  errorTitleInnerRect = null,
  errorLink = null,
  errorLinkRect = null

const numberOfBodies = 50

function scaleBodies(scale) {
  for (let i = 0; i < Composite.allBodies(world).length; i++) {
    let body = Composite.allBodies(world)[i]
    if (body.label === 'Moveable') {
      if (body.render.sprite.xScale !== scale) {
        let newScale = scale / body.render.sprite.xScale
        body.render.sprite.xScale = scale
        body.render.sprite.yScale = scale
        Body.scale(body, newScale, newScale)
      }
    }
  }
}

function canvasResize() {
  Matter.Composite.remove(world, [title, link])

  if (canvas) {
    canvas.width = window.innerWidth * 2
    canvas.height = window.innerHeight * 2
    canvas.style.width = `${window.innerWidth}px`
    canvas.style.height = `${window.innerHeight}px`
  }

  errorTitle = document.querySelector('.error-title')
  errorTitleInner = document.querySelector('.error-title-inner')
  errorTitleRect = errorTitle.getBoundingClientRect()
  errorTitleInnerRect = errorTitleInner.getBoundingClientRect()
  errorLink = document.querySelector('.error-link')
  errorLinkRect = errorLink.getBoundingClientRect()
  title = Bodies.rectangle(
    errorTitleRect.left + errorTitleRect.width / 2,
    errorTitleRect.top + errorTitleRect.height / 2,
    errorTitleInnerRect.width,
    errorTitleRect.height,
    {
      isStatic: true,
      render: {
        fillStyle: 'transparent',
      },
    }
  )
  link = Bodies.rectangle(
    errorLinkRect.left + errorLinkRect.width / 2,
    errorLinkRect.top + errorLinkRect.height / 2,
    errorLinkRect.width,
    errorLinkRect.height,
    {
      isStatic: true,
      render: {
        fillStyle: 'transparent',
      },
    }
  )

  Matter.Composite.add(world, [title, link])
  Matter.Body.setPosition(ceiling, {
    x: window.innerWidth / 2,
    y: -10,
  })
  Matter.Body.setPosition(ground, {
    x: window.innerWidth / 2,
    y: window.innerHeight + 10,
  })
  Matter.Body.setPosition(wallLeft, {
    x: -10,
    y: window.innerHeight / 2,
  })
  Matter.Body.setPosition(wallRight, {
    x: window.innerWidth + 10,
    y: window.innerHeight / 2,
  })
  clearTimeout(resizeTimer)
  resizeTimer = setTimeout(function () {
    let widthScale = window.innerWidth / prevWidth
    let heightScale = window.innerHeight / prevHeight

    Body.scale(ceiling, widthScale, 1)
    Body.scale(ground, widthScale, 1)
    Body.scale(wallLeft, 1, heightScale)
    Body.scale(wallRight, 1, heightScale)
    prevWidth = window.innerWidth
    prevHeight = window.innerHeight
  }, 100)
  if (window.innerWidth > 1200) {
    scaleBodies(0.5)
  }
  if (window.innerWidth > 768 && window.innerWidth < 1200) {
    scaleBodies(0.4)
  }
  if (window.innerWidth < 768) {
    scaleBodies(0.3)
  }
}

const initializeWorld = () => {
  canvas = matterCanvas.value
  engine = Engine.create()
  world = engine.world
  engine.gravity.x = 0
  engine.gravity.y = 100
  engine.timing.timeScale = 0.6
  render = Render.create({
    canvas: canvas,
    engine: engine,
    options: {
      background: 'transparent',
      wireframes: false,
      width: window.innerWidth,
      height: window.innerHeight,
      pixelRatio: 2,
    },
  })
  Render.run(render)
  runner = Runner.create()
  Runner.run(runner, engine)

  console.log()

  const imageList = [
    'https://em-content.zobj.net/thumbs/160/apple/354/palm-tree_1f334.png',
    'https://em-content.zobj.net/thumbs/160/apple/354/flamingo_1f9a9.png',
    'https://em-content.zobj.net/thumbs/160/apple/354/coconut_1f965.png',
    'https://em-content.zobj.net/thumbs/160/apple/354/cherry-blossom_1f338.png',
    'https://em-content.zobj.net/thumbs/160/apple/354/pizza_1f355.png',
    'https://em-content.zobj.net/thumbs/160/apple/354/hot-dog_1f32d.png',
    'https://em-content.zobj.net/thumbs/160/apple/354/thong-sandal_1fa74.png',
    'https://em-content.zobj.net/thumbs/160/apple/354/mirror-ball_1faa9.png',
  ]

  errorTitle = document.querySelector('.error-title')
  errorTitleInner = document.querySelector('.error-title-inner')
  errorTitleRect = errorTitle.getBoundingClientRect()
  errorTitleInnerRect = errorTitleInner.getBoundingClientRect()
  errorLink = document.querySelector('.error-link')
  errorLinkRect = errorLink.getBoundingClientRect()
  title = Bodies.rectangle(
    errorTitleRect.left + errorTitleRect.width / 2,
    errorTitleRect.top + errorTitleRect.height / 2,
    errorTitleInnerRect.width,
    errorTitleRect.height,
    {
      isStatic: true,
      render: {
        fillStyle: 'transparent',
      },
    }
  )
  link = Bodies.rectangle(
    errorLinkRect.left + errorLinkRect.width / 2,
    errorLinkRect.top + errorLinkRect.height / 2,
    errorLinkRect.width,
    errorLinkRect.height,
    {
      isStatic: true,
      render: {
        fillStyle: 'transparent',
      },
    }
  )

  ceiling = Bodies.rectangle(
    window.innerWidth / 2,
    -25,
    window.innerWidth,
    50,
    {
      isStatic: true,
      render: {
        visible: false,
      },
    }
  )
  ground = Bodies.rectangle(
    window.innerWidth / 2,
    window.innerHeight + 25,
    window.innerWidth,
    50,
    {
      isStatic: true,
      render: {
        visible: false,
      },
    }
  )
  wallLeft = Bodies.rectangle(
    -25,
    window.innerHeight / 2,
    50,
    window.innerHeight,
    {
      isStatic: true,
      render: {
        visible: false,
      },
    }
  )
  wallRight = Bodies.rectangle(
    window.innerWidth + 25,
    window.innerHeight / 2,
    50,
    window.innerHeight,
    {
      isStatic: true,
      render: {
        visible: false,
      },
    }
  )
  const palms = []
  for (let i = 0; i < numberOfBodies; i++) {
    const posX = Math.floor(Math.random() * window.innerWidth)
    const posY = -100 * i - 100

    const circle = Bodies.circle(posX, posY, 30, {
      label: 'Moveable',
      friction: 0.01,
      frictionAir: 0.0001,
      restitution: 0.3,
      render: {
        sprite: {
          texture: getRandomItemFromArray(imageList),
          yScale: 0.4,
          xScale: 0.4,
        },
      },
    })
    palms.push(circle)
  }
  const flamingos = []
  for (let i = 0; i < numberOfBodies; i++) {
    const posX = Math.floor(Math.random() * window.innerWidth)
    const posY = -100 * i - 100

    const circle = Bodies.circle(posX, posY, 30, {
      label: 'Moveable',
      friction: 0.01,
      frictionAir: 0.0001,
      restitution: 0.3,
      render: {
        sprite: {
          texture: getRandomItemFromArray(imageList),
          yScale: 0.4,
          xScale: 0.4,
        },
      },
    })
    flamingos.push(circle)
  }

  Composite.add(world, [title, link, ground, wallLeft, wallRight])

  Composite.add(world, [...palms, ...flamingos])

  setTimeout(() => {
    Composite.add(world, ceiling)
  }, 10000)

  mouse = Mouse.create(render.canvas)
  mouseConstraint = MouseConstraint.create(engine, {
    mouse: mouse,
    constraint: {
      stiffness: 0.2,
      render: {
        visible: false,
      },
    },
  })
  Composite.add(world, mouseConstraint)

  render.mouse = mouse

  function getRandomItemFromArray(array) {
    const randomIndex = Math.floor(Math.random() * array.length)
    return array[randomIndex]
  }

  Events.on(mouseConstraint, 'mousedown', () => {
    if (mouseConstraint.body) {
      mouseConstraint.body.render.sprite.texture =
        getRandomItemFromArray(imageList)
    }
  })
}

var updateGravity = function (event) {
  var orientation =
      typeof window.orientation !== 'undefined' ? window.orientation : 0,
    gravity = engine.gravity

  if (orientation === 0) {
    gravity.x = Common.clamp(event.gamma, -90, 90) / 90
    gravity.y = Common.clamp(event.beta, -90, 90) / 90
  } else if (orientation === 180) {
    gravity.x = Common.clamp(event.gamma, -90, 90) / 90
    gravity.y = Common.clamp(-event.beta, -90, 90) / 90
  } else if (orientation === 90) {
    gravity.x = Common.clamp(event.beta, -90, 90) / 90
    gravity.y = Common.clamp(-event.gamma, -90, 90) / 90
  } else if (orientation === -90) {
    gravity.x = Common.clamp(-event.beta, -90, 90) / 90
    gravity.y = Common.clamp(event.gamma, -90, 90) / 90
  }
}

onMounted(() => {
  nextTick(() => {
    initializeWorld()
    mouseConstraint.mouse.element.removeEventListener(
      'mousewheel',
      mouseConstraint.mouse.mousewheel
    )
    mouseConstraint.mouse.element.removeEventListener(
      'DOMMouseScroll',
      mouseConstraint.mouse.mousewheel
    )
    if (window.innerWidth > 1200) {
      scaleBodies(0.4)
    }
    if (window.innerWidth > 768 && window.innerWidth < 1200) {
      scaleBodies(0.3)
    }
    if (window.innerWidth < 768) {
      scaleBodies(0.2)
    }

    prevWidth = window.innerWidth
    prevHeight = window.innerHeight
  })

  window.addEventListener('resize', canvasResize)
  window.addEventListener('orientationchange', canvasResize)
  window.addEventListener('deviceorientation', updateGravity)
})
// })

onUnmounted(() => {
  window.removeEventListener('resize', canvasResize)
  window.removeEventListener('orientationchange', canvasResize)
  window.removeEventListener('deviceorientation', updateGravity)
  Matter.Render.stop(render)
  Matter.Runner.stop(runner)
  Matter.World.clear(engine.world)
})
</script>

<template>
  <div ref="container" class="matter-container">
    <canvas ref="matterCanvas" class="matter-canvas"></canvas>
  </div>
</template>

<style lang="postcss">
.matter-canvas,
.matter-container {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
