// import gsap from 'gsap'
import { getKerning } from '~/utils/getKerning'
// import { CustomEase } from 'gsap/all'
// gsap.registerPlugin(CustomEase)

const delay = (ms = 0) => new Promise(r => setTimeout(r, ms))

const state = {
  // general "global" duration in sec
  durationLeave: 0.8,
  durationEnter: 1.1,

  // easeLeave: CustomEase.create('custom', '0.5, 0, 0, 1'),
  // easeEnter: CustomEase.create('custom', '0.5, 0, 0, 1'),

  // new page video should start where ols page video left off
  backgroundVideoCurrentTime: null,
  foregroundVideoCurrentTime: null,

  windowHeightBeforeTransition: 0,
}

const helpers = {
  // save the timings of the case-card videos, if there are any
  getVideoCurrentTime(target) {
    const backgroundVideo = target.querySelector('.background-wrapper video')
    const foregroundVideo = target.querySelector('.foreground-wrapper video')
    state.backgroundVideoCurrentTime = backgroundVideo?.currentTime || 0
    state.foregroundVideoCurrentTime = foregroundVideo?.currentTime || 0
  },

  // apply the saved case-card timings, if there are any (make it seamless)
  setVideoCurrentTime(el) {
    const backgroundVideo = el.querySelector('.background-wrapper video')
    const foregroundVideo = el.querySelector('.foreground-wrapper video')
    if (backgroundVideo && state.backgroundVideoCurrentTime)
      backgroundVideo.currentTime = state.backgroundVideoCurrentTime
    if (foregroundVideo && state.foregroundVideoCurrentTime)
      foregroundVideo.currentTime = state.foregroundVideoCurrentTime
  },

  interpolateAspectRatio(imageWidth, imageHeight) {
    const screenAspectRatio = window.innerWidth / window.innerHeight
    const imageAspectRatio = imageWidth / imageHeight

    // Return the interpolated width and height
    return imageAspectRatio < screenAspectRatio
      ? {
          width: window.innerWidth,
          height: window.innerWidth / imageAspectRatio,
        }
      : {
          width: window.innerHeight * imageAspectRatio,
          height: window.innerHeight,
        }
  },

  resetSwiper(el) {
    function getTranslate3d(el) {
      if (!el) return

      var values = el.style.transform.split(/\w+\(|\);?/)
      if (!values[1] || !values[1].length) {
        return []
      }
      return values[1].split(/,\s?/g)
    }

    const swiperReset = el.querySelectorAll([
      '.swiper-wrapper',
      '.swiper-slide',
      '.swiper-backface-hidden',
      '.swiper-android',
      '.swiper-ios',
    ])

    const translateValue = getTranslate3d(el.querySelector('.swiper-wrapper'))
    if (swiperReset.length) {
      swiperReset.forEach(elem => {
        elem.style.transform = 'unset'
        elem.style.overflow = 'unset'
      })
      el.querySelector('.swiper-wrapper').style.left = translateValue[0]
    }
  },

  pauseForegroundBackground(target) {
    const foregroundVideo = target.querySelector('.foreground-wrapper video')

    if (foregroundVideo) {
      foregroundVideo.pause()
    }

    const backgroundVideo = target.querySelector('.background-wrapper video')

    if (backgroundVideo) {
      backgroundVideo.pause()
    }
  },

  getInterpolatedAspectRatio(target) {
    const backgroundMedia =
      target.querySelector('.background-wrapper img') ||
      target.querySelector('.background-wrapper video')

    if (!backgroundMedia)
      return {
        width: window.innerWidth,
        height: window.innerHeight,
      }

    const mediaWidth =
      parseInt(backgroundMedia.getAttribute('width')) ||
      backgroundMedia.videoWidth
    const mediaHeight =
      parseInt(backgroundMedia.getAttribute('height')) ||
      backgroundMedia.videoHeight

    return this.interpolateAspectRatio(mediaWidth, mediaHeight)
  },

  setScaleToCurrentScale(target) {
    const fg = target.querySelector('.background-wrapper')
    const bg = target.querySelector('.background-wrapper')

    fg.style.scale = getComputedStyle(fg).scale
    bg.style.scale = getComputedStyle(bg).scale
  },
}

/**
 * transition
 */

function onBeforeLeave(el, payload) {
  if (!payload) return

  helpers.resetSwiper(el)

  state.windowHeightBeforeTransition = window.innerHeight

  // setup initial position (same place as original position)
  const container = payload.parentElement
  const { width, height, left, top } = container.getBoundingClientRect()
  const interpolated = helpers.getInterpolatedAspectRatio(payload)

  container.style.width = width + 'px'
  container.style.height = height + 'px'

  document.body.append(payload)

  Object.assign(payload.style, {
    position: 'fixed',
    willChange: 'transform',

    width: width + 'px',
    height: height + 'px',

    top: -(interpolated.height - window.innerHeight) / 2 + 'px',
    right: -(interpolated.width - window.innerWidth) / 2 + 'px',
    bottom: -(interpolated.height - window.innerHeight) / 2 + 'px',
    left: -(interpolated.width - window.innerWidth) / 2 + 'px',

    transform: `translate(${
      left + (interpolated.width - window.innerWidth) / 2
    }px, ${top + (interpolated.height - window.innerHeight) / 2}px)`,

    zIndex: 25,
  })

  helpers.pauseForegroundBackground(payload)
}

async function onLeave(el, done, payload) {
  const { getCurrentTheme } = useTheme()

  const { background, text } = getCurrentTheme()

  el.style.backgroundColor = background.rgbString
  el.style.color = text.rgbString

  await delay(0)

  // hide case-teaser text and tags
  const zoomElementText = payload.querySelector('.content')

  zoomElementText.style.transtition = `opacity ${state.durationLeave / 2}s`
  zoomElementText.style.opacity = 0

  const interpolated = helpers.getInterpolatedAspectRatio(payload)

  // move/scale the case-teaser to fullscreen
  payload.style.transition = `all ${state.durationLeave}s cubic-bezier(0.5, 0, 0, 1)`

  Object.assign(payload.style, {
    width: interpolated.width + 'px',
    height: interpolated.height + 'px',
    transform: 'translate(0px, 0px)',
  })

  setTimeout(done, state.durationLeave * 1000)
}

/**
 * move the hero media and title down,
 * after the leave transition is done
 */
async function onEnter(el, done, payload) {
  el.style.opacity = 0

  await delay(state.durationLeave * 1000)

  el.style.opacity = 1

  // wait for app.vue to scroll to top
  setTimeout(() => {
    const topbar = el.querySelector('.top-bar')
    const title = el.querySelector('h1')

    if (title) {
      const initialTitle = title.textContent
      const titleKerning = getKerning(initialTitle.toUpperCase())

      title.textContent = ''

      titleKerning.forEach(item => {
        const span = document.createElement('span')
        span.style.display = 'inline-block'
        span.style.marginLeft = item.marginLeft + 'em'
        span.textContent = item.letter
        title.append(span)

        span.style.opacity = 0
        span.style.transform = 'translateY(-50%)'

        setTimeout(() => {
          span.style.transition = `all ${state.durationEnter}s ${
            Math.random() * 0.25
          }s cubic-bezier(0.5, 0, 0, 1)`
          span.style.opacity = 1
          span.style.transform = 'translateY(0%)'
        })
      })
    }

    const mediaContainer = el.querySelector('.media-container')
    const { top } = mediaContainer.getBoundingClientRect()

    const newHeight = Math.min(window.innerHeight, (window.innerWidth * 4) / 3)
    const newHeightOffset = state.windowHeightBeforeTransition - newHeight

    const background = payload.querySelector('.background-wrapper')

    Object.assign(background.style, {
      transition: `all ${state.durationEnter}s cubic-bezier(0.5, 0, 0, 1)`,
      transform: `translateY(${top - newHeightOffset / 2}px)`,
      height: newHeight + 'px',
      width: window.innerWidth + 'px',
    })

    const foreground = payload.querySelector('.foreground-wrapper')

    Object.assign(foreground.style, {
      transition: `all ${state.durationEnter}s ${
        state.durationEnter / 25
      }s cubic-bezier(0.5, 0, 0, 1)`,
      transform: `translateY(${top - newHeightOffset / 2}px)`,
      height: newHeight + 'px',
    })

    topbar.style.transform = 'translate(-20%)'

    setTimeout(() => {
      topbar.style.transition = `transform ${state.durationEnter}s cubic-bezier(0.5, 0, 0, 1)`
      topbar.style.transform = 'translate(0%)'
    })

    title.style.opacity = 0
    title.style.transform = 'translate(-50%)'

    setTimeout(() => {
      title.style.transition = `all ${state.durationEnter}s ${
        state.durationEnter / 20
      }s cubic-bezier(0.5, 0, 0, 1)`
      title.style.opacity = 1
      title.style.transform = 'translate(0%)'
    })

    setTimeout(() => {
      helpers.getVideoCurrentTime(payload)
      helpers.setVideoCurrentTime(el)

      payload?.remove()

      done()
    }, state.durationEnter * 1000)
  }, 100)
}

function onAfterEnter(el, payload) {
  const topbar = el.querySelector('.top-bar')
  const title = el.querySelector('h1')

  const els = [topbar, title]

  els.forEach(el => el.removeAttribute('style'))
}

export default {
  onBeforeLeave,
  onLeave,

  onEnter,
  onAfterEnter,
}
